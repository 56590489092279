import jQuery from 'jquery'
import './lib/jquery.resizeimagetoparent'
import AOS from './lib/aos'
import magnificPopup from 'magnific-popup'

jQuery( document ).ready( function() {

	jQuery('#menu-sub-menu a').each(function() {
		jQuery(this).attr('title', jQuery(this).text() );
	});

	// play/pause video
	jQuery('.js-pause-video').click(function(event) {
		jQuery(this).toggleClass('is-paused');
		jQuery(this).hasClass('is-paused') ? jQuery(this).text('Play') : jQuery(this).text('Pause');
		const {currentTarget} = event;
		const video = jQuery(currentTarget).prev('video')[0];
		const method = video.paused ? 'play' : 'pause';

		video[method]()
	});

	// open full video -----------this has been scrapped, to allow a regular full video link
	// jQuery('.js-open-video').magnificPopup({
	// 	type:'inline',
	// 	midClick: true,
	// 	callbacks: {
	// 		open: function() {
	// 			jQuery(this.content).find('video')[0].play();
	// 			if ( !jQuery('.js-pause-video').hasClass('is-paused') )
	// 			jQuery('.js-pause-video').trigger('click');
	// 		},
	// 		close: function() {
	// 			jQuery(this.content).find('video')[0].load();
	// 		}
	// 	}
	// });


	// animate line {

	// setTimeout(function(){

	//   jQuery('.layer .strokeV').animate({height: 300}, 1000);

	// }, 1000);



	// When scrolled down a bit, collapse menu height

	jQuery(window).scroll(function () {

		var sc = jQuery(window).scrollTop();

		var wh = jQuery(window).height();

		if (sc > 50) {

			jQuery(".layerHero .strokeV").addClass('nottop');

			jQuery(".layerHero .strokeV").removeClass('top');

		} else {

			 jQuery(".layerHero .strokeV").removeClass('nottop');

			 jQuery(".layerHero .strokeV").addClass('top');

		}

	}); // end of scroll events





	// jQuery('#mobileMenuButton').click(function() {

	// 	if(jQuery('#navMain').hasClass('show')) {

	// 		jQuery('#navMain').removeClass('show');

	// 	} else {

	// 		jQuery('#navMain').addClass('show');

	// 	}

	// });

	jQuery('.js-hamburger').click(function() {

		jQuery('#navMain').toggleClass('show')
		jQuery('.js-hamburger').toggleClass('is-active')
		if ( jQuery('#navMain').hasClass('show')) {
		jQuery('.js-hamburger').attr('aria-expanded', 'true')
		} else {
		jQuery('.js-hamburger').attr('aria-expanded', 'false')
		}

	});





	// restrict hovers to non-touch devices ----------------------------------------------------------------------------------------------------------------------------------

	var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);

	if (!touchsupport){ // browser doesn't support touch

		document.documentElement.className += " non-touch";

	}



	// verticenter banners ----------------------------------------------------------------------------------------------------------------------------------

	function centerVertically() {

		var outerHeight, innerHeight, myOffset, centeredLayers, parent;

		centeredLayers = document.querySelectorAll(".centerVertically");

		for ( let i = 0, len = centeredLayers.length; i < len; i++) {
			console.log('test', i )

			centeredLayer = centeredLayers[i];

			parent = centeredLayer.parentNode;

			outerHeight = jQuery(parent).height();

			innerHeight = jQuery(centeredLayer).height();

			myOffset = ((outerHeight - innerHeight)/ 2) ;

			myOffset = myOffset + "px";

			//console.log(centeredLayer);

			//console.log(outerHeight);

			//console.log(innerHeight);

			console.log(myOffset);

			centeredLayer.style['padding-top'] = myOffset;

		}

	}
	centerVertically();





	// verticenter team----------------------------------------------------------------------------------------------------------------------------------

	function scaleTeamImage() {

		var sibling, siblingHeight, scaledImages;

		scaledImages = document.querySelectorAll(".teamMember .image");

		for ( let i = 0, len = scaledImages.length; i < len; i++) {

			let scaledImage = scaledImages[i];

			sibling = jQuery(scaledImage).parent().children('.text');

			siblingHeight = jQuery(sibling).height();

			siblingHeight = siblingHeight  + "px";

			scaledImage.style.height = siblingHeight;

		}

	} scaleTeamImage();





	// expander ----------------------------------------------------------------------------------------------------------------------------------

	jQuery(function () {

		//jQuery('.expander').simpleexpand();

	});



	// place this within dom ready function

	function setFillParents() {

		jQuery('.fillParent').resizeToParent();

	} setFillParents();



	//  resize ----------------------------------------------------------------------------------------------------------------------------------

	jQuery( window ).resize(function() {

		centerVertically();

		scaleTeamImage();

	});



	// fire resize event once, fixed homepage footer video bug

	jQuery( window ).trigger('resize');







      AOS.init({

        easing: 'ease-in-out-cubic',

        once: true,

        duration: 1000,

        offset: 0,

      });





});





jQuery(window).load(function(){

		function scaleTeamImage() {

		var sibling, siblingHeight, scaledImages;

		scaledImages = document.querySelectorAll(".lazyload");

		for ( let i = 0, len = scaledImages.length; i < len; i++) {

			let scaledImage = scaledImages[i];

			jQuery(scaledImage).removeClass('lazyload');

		}

	} scaleTeamImage();

});

